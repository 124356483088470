import React, { useEffect } from 'react'
import { Meta, Title } from 'react-head'
import DefaultLayout from '../components/DefaultLayout'
import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

function ErrorPage(error) {
  useEffect(() => {
    throw new Error(
      `ErrorPage: "${document.location.pathname}" got error ${error.error}`,
    )
  }, [error])

  return (
    <DefaultLayout>
      <Meta name="robots" content="noindex" />
      <Title>Page not found - Lifesum</Title>
      <Header inverted={false} />
      <div
        className={`
        o-width-limiter relative
        z-10 mb-96
        mt-24 max-w-1024 text-center
        md:mt-48
        lg:mt-128 lg:text-left
        xxl:max-w-1280
      `}
      >
        <h1 className="type-large-plus quincy mb-24">Oops! Page not found</h1>
        <p className="s-text">
          We seem to have messed something up. Sorry about that. Have a look at
          the address to see if you can spot an error.
        </p>
        <a href="http://www.lifesum.com" className="c-button">
          <span>Go to lifesum.com</span>
        </a>
      </div>
      <Footer roomForContent={'small'} />
    </DefaultLayout>
  )
}

export default ErrorPage
